// extracted by mini-css-extract-plugin
export const carouselContainer = "scroller-base-module--carousel-container--21olR";
export const scrollItem = "scroller-base-module--scroll-item--3Wlcf";
export const activeButton = "scroller-base-module--active-button--3-YAn";
export const inactiveButton = "scroller-base-module--inactive-button--18oOn";
export const activeButtonInverted = "scroller-base-module--active-button-inverted--2rslW";
export const inactiveButtonInverted = "scroller-base-module--inactive-button-inverted--3B5A9";
export const customRightArrow = "scroller-base-module--custom-right-arrow--3m10_";
export const customLeftArrow = "scroller-base-module--custom-left-arrow--32XcD";
export const customRightArrowHidden = "scroller-base-module--custom-right-arrow-hidden--1hC05";
export const customLeftArrowHidden = "scroller-base-module--custom-left-arrow-hidden--d_toT";
export const imageContainer = "scroller-base-module--image-container--1tmsq";
export const scrollContainer = "scroller-base-module--scroll-container--ls3EC";