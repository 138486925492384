import React, { useState } from 'react'
import Img from 'gatsby-image'
import Carousel from 'react-multi-carousel'
import * as baseScrollerStyles from './scroller-base.module.scss'

export const CustomDot = ({
    index,
    onClick,
    active,
    carouselState: { currentSlide },
    isInverted,
}) => {
    const activeStyle = isInverted
        ? baseScrollerStyles.activeButtonInverted
        : baseScrollerStyles.activeButton
    const passiveStyle = isInverted
        ? baseScrollerStyles.inactiveButtonInverted
        : baseScrollerStyles.inactiveButton
    return (
        <span
            onClick={e => {
                onClick()
                e.preventDefault()
            }}
            className={active ? activeStyle : passiveStyle}
        ></span>
    )
}

export const CustomLeftArrow = ({ isMouseOver, onClick }) => {
    return (
        <i
            onClick={() => onClick()}
            className={
                isMouseOver
                    ? baseScrollerStyles.customLeftArrow
                    : baseScrollerStyles.customLeftArrowHidden
            }
        />
    )
}
export const CustomRightArrow = ({ isMouseOver, onClick }) => {
    return (
        <i
            className={
                isMouseOver
                    ? baseScrollerStyles.customRightArrow
                    : baseScrollerStyles.customRightArrowHidden
            }
            onClick={() => onClick()}
        />
    )
}

const ImageScroller = props => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: props.itemsOnDesktop || 3,
            partialVisibilityGutter:
                props.desktopVisibilityGutter !== null
                    ? props.desktopVisibilityGutter
                    : 0,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: props.itemsOnDesktop || 3,
            partialVisibilityGutter:
                props.desktopVisibilityGutter !== null
                    ? props.desktopVisibilityGutter
                    : 0,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: props.itemsOnTablet || 2,
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: props.itemsOnMobile || 1,
            partialVisibilityGutter:
                props.mobileVisibilityGutter !== null
                    ? props.mobileVisibilityGutter
                    : 0,
        },
    }

    // let CarouselRef
    const [CarouselRef, setCarouselRef] = useState(null)
    const [initialRender, setInitialRender] = useState(true)
    const [transformState, setTransformState] = useState(0)
    const beforeChangeTransform = props.beforeChangeTransform || 0
    const incrementer = props.beforeChangeIncrement || 0

    if (props.gotoLast && CarouselRef) {
        const lastSlide = CarouselRef.state.totalItems - 1
        CarouselRef.goToSlide(lastSlide)
        setInitialRender(false)
    }

    return (
        <div
            className={
                props.carouselContainer || baseScrollerStyles.carouselContainer
            }
        >
            <Carousel
                ref={el => setCarouselRef(el)}
                responsive={responsive}
                showDots
                renderDotsOutside
                removeArrowOnDeviceType={['mobile']}
                customLeftArrow={<CustomLeftArrow isMouseOver={props.isMouseOver} />}
                customRightArrow={<CustomRightArrow isMouseOver={props.isMouseOver} />}
                itemClass={props.itemClass || baseScrollerStyles.imageContainer}
                containerClass={
                    props.scrollContainer || baseScrollerStyles.scrollContainer
                }
                sliderClass={props.sliderClass || baseScrollerStyles.slider}
                customDot={<CustomDot isInverted={props.isInverted} />}
                centerMode={props.centerMode || false}
                minimumTouchDrag={props.touchDrag || 80}
                infinite={props.infinite || false}
                partialVisbile={props.partialVisbile || false}
                additionalTransfrom={transformState}
                slidesToSlide={props.slidesToSlide || 1}
                beforeChange={nextSlide => {
                    const transformMetric =
                        beforeChangeTransform + nextSlide * incrementer
                    if (nextSlide !== 0 && transformState !== transformMetric) {
                        setTransformState(transformMetric)
                    }
                    if (nextSlide === 0) {
                        setTransformState(0)
                    }
                }}
            >
                {props.images.map(image => {
                    return (
                        <img
                            className={
                                props.scrollImage ||
                                baseScrollerStyles.scrollImage
                            }
                            src={image.url}
                            draggable="false"
                            alt=""
                        />
                    )
                })}
            </Carousel>
        </div>
    )
}

export const ComponentScroller = props => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: props.itemsOnDesktop || 3,
            partialVisibilityGutter:
                props.desktopVisibilityGutter !== null
                    ? props.desktopVisibilityGutter
                    : 0,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: props.itemsOnDesktop || 3,
            partialVisibilityGutter:
                props.desktopVisibilityGutter !== null
                    ? props.desktopVisibilityGutter
                    : 0,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: props.itemsOnTablet || 2,
        },
        mobile: {
            breakpoint: { max: 664, min: 0 },
            items: props.itemsOnMobile || 1,
            partialVisibilityGutter:
                props.mobileVisibilityGutter !== null
                    ? props.mobileVisibilityGutter
                    : 0,
        },
    }

    const [CarouselRef, setCarouselRef] = useState(null)
    const [initialRender, setInitialRender] = useState(true)
    const [transformState, setTransformState] = useState(0)
    const beforeChangeTransform = props.beforeChangeTransform || 0
    const incrementer = props.beforeChangeIncrement || 0

    if (props.gotoLast && CarouselRef && initialRender) {
        const lastSlide = CarouselRef.state.totalItems - 2
        CarouselRef.goToSlide(lastSlide)
        setInitialRender(false)
    }

    return (
        <div
            className={
                props.carouselContainer || baseScrollerStyles.carouselContainer
            }
        >
            <Carousel
                ref={el => setCarouselRef(el)}
                responsive={responsive}
                centerMode={props.centerMode}
                showDots
                // renderDotsOutside
                removeArrowOnDeviceType={['mobile']}
                customLeftArrow={<CustomLeftArrow isMouseOver={props.isMouseOver} />}
                customRightArrow={<CustomRightArrow isMouseOver={props.isMouseOver} />}
                itemClass={props.itemClass || baseScrollerStyles.imageContainer}
                containerClass={
                    props.scrollContainer || baseScrollerStyles.scrollContainer
                }
                beforeChange={nextSlide => {
                    const transformMetric =
                        beforeChangeTransform + nextSlide * incrementer
                    if (nextSlide !== 0 && transformState !== transformMetric) {
                        setTransformState(transformMetric)
                    }
                    if (nextSlide === 0) {
                        setTransformState(0)
                    }
                }}
                sliderClass={props.sliderClass || baseScrollerStyles.slider}
                customDot={<CustomDot isInverted={props.isInverted} />}
                centerMode={props.centerMode || false}
                minimumTouchDrag={props.touchDrag || 20}
                infinite={props.infinite || false}
                partialVisible={props.partialVisbile || false}
                additionalTransfrom={transformState}
                slidesToSlide={props.slidesToSlide || 1}
            >
                {props.children}
            </Carousel>
        </div>
    )
}

ComponentScroller.defaultProps = {
    isInverted: false,
    isMouseOver: true,
}

export default ImageScroller

ImageScroller.defaultProps = {
    isInverted: false,
    isMouseOver: true,
}
